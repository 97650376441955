<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="alertMsg">
      <b-alert v-model="showDismissibleAlert" variant="success" dismissible>
        Company Registered Successfully!
      </b-alert>
    </div>


    <form @submit.prevent="submitCompanyForm" method="post" >
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="mb-3">
                <label class="form-label" for="formrow-companyName-input">Company Name *</label>
                <input type="text" class="form-control" id="formrow-name-input" v-model="posts.companyName"
                       :class="{
                          'is-invalid': submitted && $v.posts.companyName.$error,
                        }"
                >
                <div
                    v-if="submitted && $v.posts.companyName.$error"
                    class="invalid-feedback"
                >
                        <span v-if="!$v.posts.companyName.required"
                        >This value is required.</span
                        >
                </div>
              </div>
              <div class="row mb-3">
                <div class=" col-6">
                  <label class="form-label" for="formrow-adminName-input">Owner Name *</label>
                  <input type="text" class="form-control" id="formrow-adminName-input" v-model="posts.adminName"
                         :class="{
                                      'is-invalid': submitted && $v.posts.adminName.$error,
                                  }"
                  >
                  <div
                      v-if="submitted && $v.posts.adminName.$error"
                      class="invalid-feedback"
                  >
                                  <span v-if="!$v.posts.adminName.required"
                                  >This value is required.</span
                                  >
                  </div>
                </div>
                <div class="col-6">
                  <label class="form-label">Owner Phone No.*</label>
                  <input class="form-control" type="text" min="0" oninput='this.value=(parseInt(this.value)||"")'
                         v-model="posts.phoneNO"
                         :class="{
                                  'is-invalid': submitted && $v.posts.phoneNO.$error,
                              }">

                  <div
                      v-if="submitted && $v.posts.phoneNO.$error"
                      class="invalid-feedback"
                  >
                              <span v-if="!$v.posts.phoneNO.required"
                              >This value is required.</span
                              >
                    <span v-if="!$v.posts.phoneNO.maxLength"
                    >This value is too long. It should have 10 digits</span
                    >
                    <span v-if="!$v.posts.phoneNO.minLength"
                    >This value length is invalid. It should be
                                10 digits</span
                    >
                  </div>
                </div>

                <div class="mb-3 mt-3 col-6">
                  <label class="form-label" for="formrow-emailID-input">Owner Email ID*</label>
                  <input type="text" class="form-control" id="formrow-emailID-input" v-model="posts.emailID"
                         :class="{
                                      'is-invalid': submitted && $v.posts.emailID.$error,
                                  }"
                  >
                  <div
                      v-if="submitted && $v.posts.emailID.$error"
                      class="invalid-feedback"
                  >
                                  <span v-if="!$v.posts.emailID.required"
                                  >This value is required.</span
                                  >
                    <span v-if="!$v.posts.emailID.email"
                    >This value should be a valid email.</span
                    >
                  </div>
                </div>
                <div class="mb-3 mt-3 col-6">
                  <label> Login Type: </label>
                  <multiselect
                      v-model="loginType"
                      :options="loginTypeArr"
                      :show-labels="false"
                      track-by="id"
                      label="name"
                      :class="{'is-invalid': submitted && $v.loginType.$error,}"
                      @input="loadRoleTypeData()"
                  >
                  </multiselect>
                  <div v-if="submitted && $v.loginType.$error" class="invalid-feedback">
                    <span v-if="!$v.loginType.required">This value is required.</span>
                  </div>
                </div>
                <div class="mb-3 col-6">
                  <label> Role </label>
                  <multiselect
                      v-model="role"
                      :options="roles"
                      :show-labels="false"
                      track-by="id"
                      label="name"
                      :class="{'is-invalid': submitted && $v.loginType.$error,}"
                  >
                  </multiselect>
                  <div v-if="submitted && $v.role.$error" class="invalid-feedback">
                    <span v-if="!$v.role.required">This value is required.</span>
                  </div>
                </div>
              </div>
              <div class="row" style="float:right;">
                <b-spinner v-if="loading"
                           class="m-2 col-3"
                           variant="primary"
                           role="status"
                ></b-spinner>

                <button v-if="$can('create food company')" type="submit" class="col-3 btn btn-themeYellow w-md waves-effect waves-light" :disabled="loading">Submit</button>
              </div>
            </div>
          </div>

        </div>


      </div>



      <!-- <button class="btn btn-themeYellow" type="submit" style="position: fixed; bottom: 6px; right: 6px;border-radius: 50%;
    padding: 19px;z-index:500;"><i class="fas fa-check fa-2x"></i></button> -->
    </form>
    <!-- Success circle button with ripple effect -->



  </Layout>
</template>
<script>
// import Switches from "vue-switches";
import Multiselect from "vue-multiselect";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import {
  required, email, maxLength, minLength
} from "vuelidate/lib/validators";


/**
 * Advanced-form component
 */
export default {
  page: {
    title: "Add Company",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Multiselect,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Add Company",
      items: [
        {
          text: "Add Company",
          href: "/",
        },
        {
          text: "Add",
          active: true,
        },
      ],
      status: '',
      submitted: false,
      showDismissibleAlert: false,
      loading: false,
      notificationText: "",
      alertvariant: "",
      posts: {
        companyName: null,
        adminName: null,
        emailID: '',
        phoneNO: '',
      },
      loginTypeArr: [
        { name: "CloudKitch Admin", id: 1 },
        { name: "Restaurant", id: 2 },
        { name: "Corporate", id: 3 },
        { name: "Principle", id: 4 },
      ],
      loginType: "",
      roles:[],
      role:"",
    };
  },

  validations: {
    posts: {
      companyName: {
        required,
      },
      adminName: {
        required,
      },
      emailID: {
        required,
        email,
      },
      phoneNO: {
        required,
        maxLength: maxLength(10),
        minLength: minLength(10),
      },
    },
    loginType:{
      required,
    },
    role:{
      required,
    }
  },
  methods: {

    submitCompanyForm(e) {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;
        let formData = new FormData();

        formData.append('emailID', this.posts.emailID);
        formData.append('phoneNO', this.posts.phoneNO);
        formData.append('adminName', this.posts.adminName);
        formData.append('companyName', this.posts.companyName);
        formData.append('login_type', this.loginType.id);
        formData.append('role', JSON.stringify(this.role));

        e.preventDefault();
        this.axios.post(this.$loggedRole+"/company", formData,
           )
            .then((result) => {
              this.$swal({
                text: result.data.message,
                icon: "success",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000
              });
              this.loading = false;
              setTimeout(() => {
                this.$router.push('/form/restaurantForm/:' + result.data.data);
              }, 2000);
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: error.response.data.message,
                icon: "error",
              });
            });
      }
    },
    loadRoleTypeData() {
      this.axios
          .get(this.$loggedRole+"/login-type-roles/"+this.loginType.id)
          .then((result) => {
            this.roles = result.data.data;
          });

    },
  },
  middleware: "authentication",
};
</script>



<style lang="scss" scoped>

img.preview {
  width: 100px;
  height: 100px;
  background-color: white;
  border: 1px solid #DDD;
  padding: 5px;
  object-fit: contain;
  margin-top: 6px;
}

.alertMsg {
  position: fixed;
  right: 0px;
  z-index: 500;
  top: 79px;
  width: 22%;
}
</style>


